// Generated by Framer (9bf39e5)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useRouteElementId, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/xZndidUCt";

const enabledGestures = {aCgLB9BpF: {hover: true}};

const cycleOrder = ["aCgLB9BpF"];

const variantClassNames = {aCgLB9BpF: "framer-v-tslb3s"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "aCgLB9BpF", title: KNv5fdJEC = "Szolgáltatásaink", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "aCgLB9BpF", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const id1 = useRouteElementId("IFheoxxvw", "pH3J1KNIf")

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-LL4vw", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Link href={{hash: id1, webPageId: "pH3J1KNIf"}} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx("framer-tslb3s", className)} framer-9ch584`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"aCgLB9BpF"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"aCgLB9BpF-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"} style={{"--framer-text-alignment": "center"}}>Szolgáltatásaink</motion.p></React.Fragment>} className={"framer-13bv2hz"} layoutDependency={layoutDependency} layoutId={"JjKCXvwAB"} style={{"--framer-paragraph-spacing": "0px", textShadow: "none"}} text={KNv5fdJEC} transformTemplate={transformTemplate} transition={transition} variants={{"aCgLB9BpF-hover": {textShadow: "0px 0px 4px rgb(102, 120, 255)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({"aCgLB9BpF-hover": {children: <React.Fragment><motion.p className={"framer-styles-preset-21ogod"} data-styles-preset={"xZndidUCt"}>Szolgáltatásaink</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-LL4vw [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-LL4vw * { box-sizing: border-box; }", ".framer-LL4vw .framer-9ch584 { display: block; }", ".framer-LL4vw .framer-tslb3s { height: 32px; overflow: visible; position: relative; text-decoration: none; width: 125px; }", ".framer-LL4vw .framer-13bv2hz { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-LL4vw .framer-v-tslb3s .framer-tslb3s { cursor: pointer; }", ".framer-LL4vw.framer-v-tslb3s.hover .framer-tslb3s { overflow: hidden; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 125
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"NJeZ5RB78":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"KNv5fdJEC":"title"}
 */
const FrameraJgT4QPCH: React.ComponentType<Props> = withCSS(Component, css, "framer-LL4vw") as typeof Component;
export default FrameraJgT4QPCH;

FrameraJgT4QPCH.displayName = "Szolgáltatásaink";

FrameraJgT4QPCH.defaultProps = {height: 32, width: 125};

addPropertyControls(FrameraJgT4QPCH, {KNv5fdJEC: {defaultValue: "Szolgáltatásaink", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FrameraJgT4QPCH, [...sharedStyle.fonts])